import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <App>
        <Helmet>
          <title>CAMS</title>
        </Helmet>
      </App>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
