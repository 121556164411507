// libraries
import React, { useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet-async';
import SinglePagePDFViewer from './singlePagePDF';
import { Link, useParams, Redirect } from 'react-router-dom';

import { ToastContainer, toast } from 'react-toastify';
// Components
import 'react-toastify/dist/ReactToastify.css';
import { formatNumber } from '../../utils/utils';

import axios from 'axios';

import YouTube from 'react-youtube';
var FileSaver = require('file-saver');
var regpan = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}
function youtube_parser(url) {
  var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  var match = url.match(regExp);
  return match && match[7].length == 11 ? match[7] : false;
}
function isIOSDevice() {
  return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
}
const notify = text =>
  toast.success(text, {
    position: 'top-right',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
const notifyError = text =>
  toast.error(text, {
    position: 'top-right',
    autoClose: 10000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined
  });
const opts = {
  // height: '400px',
  frameborder: 0,
  width: '100%',
  playerVars: {
    frameborder: 0,
    // https://developers.google.com/youtube/player_parameters
    autoplay: 0
  }
};

function WebinarDetails() {
  const [searchKey, setSearchKey] = useState('');

  function handleChangeSearchKey(search_key) {
    setSearchKey(search_key);
  }

  function handleKeyPress(target) {
    if (target.charCode == 13) {
      handleSubmit();
    }
  }

  function handleSubmit() {
    setIsLoading(true);

    notify();
    if (regpan.test(searchKey)) {
      setIsLoading(true);

      axios
        .post(
          'https://camsbackend.vrtaxconsultants.com/getCertificate',
          {
            panno: searchKey.trim(),
            eventId: id
          },
          { responseType: 'arraybuffer' }
        )
        .then(async function (response) {
          console.log(response);

          if ((response.status = 200)) {
            var out = new Blob([response.data], { type: 'application/zip' });
            axios
              .post(
                'https://camsbackend.vrtaxconsultants.com/getName',
                {
                  panno: searchKey.trim(),
                  eventId: id
                }
                // { responseType: 'arraybuffer' }
              )
              .then(async function (response2) {
                FileSaver.saveAs(out, response2.data.name + '.zip');
              });
            // FileSaver.saveAs(
            //   out,
            //   webinar.fileName + ' (' + searchKey + ')' + '.pdf'
            // );
            notify('Form Downloaded! Thank you!');
            setIsLoading(false);
          } else {
            let data = response.data;
            var decodedString = String.fromCharCode.apply(
              null,
              new Uint8Array(data)
            );
            var obj = JSON.parse(decodedString);
            console.log(obj['message']);
            notifyError(obj['message']);
            setIsLoading(false);
          }
        })
        .catch(async function (error) {
          let data = error.response.data;
          var decodedString = String.fromCharCode.apply(
            null,
            new Uint8Array(data)
          );
          var obj = JSON.parse(decodedString);
          console.log(obj['message']);
          notifyError(obj['message']);
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
      alert('Invalid Pan Number');
    }
  }
  // react hooks

  const [isLoading, setIsLoading] = useState(true);
  const [webinar, setwebinar] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    axios
      .get('https://camsbackend.vrtaxconsultants.com/getEvent?eventId=' + id)
      .then(response => {
        setwebinar(response.data.result);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
      });
  }, []);

  if (isLoading)
    return (
      <div className="event-details">
        <div className="loading">Loading</div>
      </div>
    );

  if (webinar && !isLoading) {
    // necessary data for rendering
    const { name, details, title, imagePath, date } = webinar;

    return (
      <div className="event-details">
        <Helmet>
          <title> {`${name} - Computer Age Management Services`}</title>
        </Helmet>
        <ToastContainer />
        <div className="top-bar">
          <Link to="/downloads" className="btn-back icon-only">
            <i
              style={{ color: 'rgba(41, 87, 164, 255)' }}
              className="fa fa-arrow-left"
            />{' '}
          </Link>
          <div className={`hashTitle`}>
            <h4 className="title">{name}</h4>
          </div>
        </div>

        <h2 className="title">{date}</h2>
        {/* <h2>In association with</h2> */}
        {/* <img width="50%" height="50%" src={iiclogo} /> */}
        <div className="details">
          <div className="chart-infos">
            {/* <div className="chart">
              <img
                style={{ borderRadius: "505px" }}
                width="90%"
                height="80%"
                src={bannerImage}
              ></img>
            
            </div> */}
            <div className="infos">
              <img
                style={{ borderRadius: '50%' }}
                width="30%"
                height="30%"
                src={imagePath}
              />

              <h2
                style={{
                  textAlign: 'center'
                }}
              >
                {title}
              </h2>
              <p>{details}</p>
              <div className="rates">
                <div
                  style={{
                    width: '350px',
                    alignContent: 'center',
                    justifyContent: 'center',
                    alignItems: 'center',
                    alignSelf: 'center'
                  }}
                  className="card"
                >
                  <h2 style={{ textAlign: 'center' }}>Download Your FORM16A</h2>
                  <div className="search" method="get">
                    <input
                      style={{ width: '350px' }}
                      onChange={e => handleChangeSearchKey(e.target.value)}
                      type="text"
                      value={searchKey}
                      placeholder="Pan No."
                      onKeyPress={handleKeyPress}
                    />
                    <button onClick={handleSubmit}>
                      <i className="fa fa-download" />
                    </button>
                  </div>
                </div>
              </div>

              <div></div>
            </div>
            {/* <div style={{ maxWidth: '650px' }} className="chart">
              <h2 style={{ textAlign: 'center' }}>
                <i className="fab fa-youtube" /> Video
              </h2>
              <div className="card">
                <YouTube videoId={youtube_parser(ytID)} opts={opts} />

              </div>
            </div> */}
          </div>
        </div>
        {/* <div className="image">
          <img width="120%" height="90%" src={webinarImageFile} />
        </div> */}
      </div>
    );
  }
  return <Redirect to="/" />;
}

export default WebinarDetails;
